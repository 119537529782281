export class ColorPalette {
  static bgColor = "#0E161B";
  static tabColor = "#150647";
  static accentColor = "#A84FBA";
  static bgGradient =
    "linear-gradient(to right, #36227C 0%, #0B091D 80%, #0B091D 100%)";
  static accentColor2 = "#3D2390";
  static accentColor2Bg = "#211652";
  static shadow1 = "0px 0px 30px rgba(11, 153, 224, 0.5)";
}

export class UserInfo {
  static myLevel = {
    name: "",
    GHUB_required: 0,
    daily_free_P2E_attempts: 0,
    extra_P2E_attempts_price: 0,
    cashback_PvP_games: 0,
    weekly_lottery_tickets: 0,
    referral_percentage: 0,
    GHUB_holders_lounge_access: false,
    revenue_share: false,
  };
}
export class BaseInfo {
  static levels2 = [
    {
      name: "",
      GHUB_required: 0,
      daily_free_P2E_attempts: 0,
      extra_P2E_attempts_price: 0,
      cashback_PvP_games: 0,
      weekly_lottery_tickets: 0,
      referral_percentage: 0,
      GHUB_holders_lounge_access: false,
      revenue_share: false,
    },
  ];
  static baseInfoDoc = {};
  static gamesNow = [];
  static gamesName = {};
  static chainTokensData = {};
  static gameTokenAddress = "0x5fd82ad8a72252a7a449aded99efec7dd8efa690";
  static ethChainID = "0x1";
  static arbChainID = "0xa4b1";
  static gamesMaxPlayers = {};
  static gamesPvpUrlType = {};
  static levelsThreshold = [];
  static p2eGames = {
    games_old: [{ url: "" }],
    games_active: [{ url: "", attempts: null }],
    start_comp: "",
    show_before_min: 15,
    start: "",
    end: "",
    show: false,
  };
  static games = {
    "": {
      link: "",
    },
  };
}

export class GroupsDoc {
  static groups = [];
}

export class TaskDoc {
  static tasks = [];
  static points = [];
}

export class StatusInfo {
  static data;
}

export const versionGame = "2.1.18";
export const isTest = false;
export const casinoVersion = "afo"; //ric | afo
