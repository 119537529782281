import { RColumn, RText, RTitile, Space } from "../compnents/otherCOmps";

import casinoBannImg from "../assets/Banner Casino.jpg";
import sportsBannerImg from "../assets/sportsbetbanner.png";

import pokerBannImg from "../assets/poker banner.png";

import gamesBannerImg from "../assets/TG platform banner.jpg";
import { useGameType } from "../theme";
import { casinoVersion, isTest, StatusInfo } from "../global/const";
import { auth } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "lodash";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { useEffect, useState } from "react";
import { BannerAlert } from "../home/tabController";

export let casinoLink;
export default function StartBanners() {
  const bannStyle = { borderRadius: "12px", cursor: "pointer" };
  const { gameType, setGameType } = useGameType();

  const [betaBannerOpen, setBetaBannerOpen] = useState(false);

  async function onClickBann(type) {
    if (type === "sport") {
      window.open("https://t.me/TG_SportsbettingBot");
      return;
    }
    setGameType(type);
  }
  function SingleBanner({ tit, img, type }) {
    const isComingSoon = !isTest
      ? type !== "games" &&
        type !== "casino" &&
        type !== "casino2" &&
        type !== "sport"
      : false;
    return (
      <div
        style={{ maxWidth: "700px" }}
        onClick={() => (!isComingSoon ? onClickBann(type) : () => {})}
      >
        <RColumn>
          <RText align={"center"} size="20px">
            {tit}
          </RText>
          <Space height="4px" />
          <div
            style={{
              position: "relative",
              width: "97%",
            }}
          >
            <img
              style={{
                ...bannStyle,
                filter: isComingSoon ? "blur(2px)" : null,
                opacity: isComingSoon ? 0.8 : null,
                width: "100%", // Assicura che l'immagine occupi tutto lo spazio del contenitore
              }}
              src={img}
            />

            {isComingSoon && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                Coming Soon
              </div>
            )}
          </div>
        </RColumn>
      </div>
    );
  }

  useEffect(() => {
    setBetaBannerOpen(!localStorage.getItem("fisrtTimeBeta2"));
  }, []);
  return (
    <>
      <BannerAlert
        show={betaBannerOpen}
        close={() => setBetaBannerOpen(false)}
        tit={"Platform Notice"}
        desc={
          <>
            Please note that GameHub is still in active development. While your
            funds and personal data are completely secure, you may encounter
            occasional bugs or errors as we continue to improve the platform. We
            appreciate your understanding and patience!
          </>
        }
        ctaTxt={"Ok"}
        onClick={() => {
          setBetaBannerOpen(false);
          localStorage.setItem("fisrtTimeBeta2", false);
        }}
      />

      <RColumn horizontalAlign="center">
        <Space />
        <RTitile>The Game Hub</RTitile>
        <Space height="40px" />
        <SingleBanner type={"games"} tit={"Games"} img={gamesBannerImg} />
        {(StatusInfo.data.qtcasino || isTest) && (
          <>
            {(isTest || casinoVersion === "afo") && (
              <>
                <Space />
                <SingleBanner
                  type={"casino"}
                  tit={"Casino"}
                  img={casinoBannImg}
                />
              </>
            )}
            <Space />
            {(isTest || casinoVersion === "ric") && (
              <>
                <SingleBanner
                  type={"casino2"}
                  tit={"Casino"}
                  img={casinoBannImg}
                />
                <Space />
              </>
            )}
          </>
        )}
        <SingleBanner type={"poker"} tit={"Poker"} img={pokerBannImg} />
        <Space />
        <SingleBanner
          type={"sport"}
          tit={"Sportsbetting"}
          img={sportsBannerImg}
        />
      </RColumn>
    </>
  );
}
